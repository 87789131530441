<template>
  <el-card class="routerBox">
    <pageTable 
      ref="multipleTable"
      :data="tableData"
      :row-key="getRowKeys"
      @sort-change="sortChange"
      v-loading="isLoading"
      :searchParams.sync="form"
      @searchEvent="getList(true)"
      @clearEvent="clearFilter"
      @updateTable="getList"
      @selection-change="handleSelectionChange">
      <template #searchForm>
        <el-select
          v-model="form.fileType"
          size="small"
          :placeholder="$t('Files.File type')"
          clearable
          style="width: 200px;"
          @change="getList(true)"
        >
          <el-option
            v-for="item in companyCustomerFileConfig"
            :key="item.configCode"
            :label="item.configName"
            :value="item.configCode"
          />
        </el-select>
        <el-input
          icon="el-icon-search"
          v-model="form.queryValue"
          :placeholder="place_content"
          style="width: 250px;"
          size="small"
          auto-complete="off"
          clearable
          @keyup.enter.native="getList(true)"
          :disabled="inputStatus"
        >
          <el-select
            v-model="form.queryType"
            slot="prepend"
            :placeholder="$t('Files.Selectrange')"
            clearable
            style="width: 100px;"
            size="small"
            @change="selectTrigger(form.queryType)"
          >
            <el-option
              v-for="item in importanceOptions.filter(row => row.value != ($store.state.user.orderAlias == 1 ? 'ci' : ''))"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-input>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          size="small"
          :start-placeholder="$t('order.Start from—End to').split('—')[0]"
          :end-placeholder="$t('order.Start from—End to').split('—')[1]"
          style="width: 250px;"
        />
      </template>
      <template #searchAction>
        <toolTipIcon 
          icon="iconfont icon-download" 
          :tooltipText="$t('ExportFile')"
          @click="openExportFileDialog"
        />
      </template>
      <template #tableColumn>
        <el-table-column
          :reserve-selection="true"
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="originalFileName"
          min-width="150"
          :label="$t('Files.Filename')"
        >
        </el-table-column>
        <el-table-column 
          prop="subject" 
          min-width="150" 
          :label="$t('home.Event')+$t('Files.Name')">
          <template slot-scope="scope">
            <div
              class="aLink"
              @click="
                linkTo(
                  `/order/orderDetails?orderId=${scope.row.orderId}&eventId=${scope.row.eventId}`
                )
              "
            >
              {{ scope.row.subject }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pi" min-width="150" :label="$isOrderPITitle()" sortable>
          <template slot-scope="scope">
            <div
              :class="!scope.row.ci ? 'aLink' : ''"
              @click="
                !scope.row.ci ? 
                linkTo(
                  `/order/orderDetails?orderId=${scope.row.orderId}`
                ) : ''
              "
            >
              {{ scope.row.pi }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="$store.state.user.orderAlias == 0" prop="ci" min-width="150" label="CI" sortable>
          <template slot-scope="scope">
            <div
              class="aLink"
              @click="
                linkTo(
                  `/order/orderDetails?orderId=${scope.row.orderId}`
                )
              "
            >
              {{ scope.row.ci }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="fileType"
          width="120"
        >
          <template slot="header" slot-scope="{$index}">
            <el-dropdown 
              :key="$index"
              trigger="click" 
              placement="bottom-start"  
              @command="fileTypeCommand">
              <span style="color:#909399">
                {{ $t('Files.Type') }}<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in companyCustomerFileConfig"
                  :key="index"
                  :command="item.configCode"
                >
                  {{ item.configName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            {{ $isFindData(companyCustomerFileConfig, scope.row.fileType, 'configCode', 'configName') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="fileSize"
          width="120"
          :label="$t('Files.Size(KB)')"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="fileSuffix"
          width="120"
          :label="$t('Files.Format')"
        >
        </el-table-column>
        <el-table-column
          prop="uploadPerson"
          width="120"
          :label="$t('Files.Creator')"
          sortable
        >
          <template slot-scope="scope">
            <div class="customerName">
              <member :info="scope.row.openUserDTO" :size="28" />
              <span>{{ scope.row.openUserDTO.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="200"
          mix-width="200"
          :label="$t('Files.CreateTime')"
          sortable
        >
        </el-table-column>
        <el-table-column :label="$t('Files.Action')" width="90" fixed="right">
          <template slot-scope="scope">
            <div class="action">
              <el-tooltip 
                effect="light" 
                popper-class="ec-tooltip"
                :content="$t('Files.File preview')" 
                placement="top">
                <div
                  class="btn" 
                  @click.stop="operation(scope.row, '预览')">
                  <i class="iconfont icon-xianshi"></i>
                </div>
              </el-tooltip>
              <el-dropdown
                trigger="click" 
                placement="bottom-start"
                @visible-change="visibleDropdownChange">
                <div
                  class="btn" 
                  :class="tableSelectedShow === scope.$index ? 'active' : ''"
                  @click="tableSelectedShow = scope.$index">
                  <i class="iconfont icon-Ellipsis"></i>
                </div>
                <el-dropdown-menu class="ec-popper" slot="dropdown">
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="el-icon-download"
                    @click.native="operation(scope.row, '下载')"
                  >{{ $t('home.Download') }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-fenxiang2"
                    @click.native="operation(scope.row, '站内分享')"
                  >{{ $t("order.Internal sharing") }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-zhanwaifenxiang"
                    @click.native="operation(scope.row, '站外分享')"
                  >{{ $t("order.External sharing") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </template>
    </pageTable>
    <fileView
      :row="{
        id: offStationData.fileId,
        fileName: offStationData.originalFileName,
        fileSuffix: offStationData.fileSuffix,
        savePath: offStationData.savePath
      }"
      :embedShow="embedShow"
      :embedUrl="embedUrl"
      @close="embedShow = false"
    ></fileView>
    <offStation
      :isOffStation="isOffStation"
      :offStationData="offStationData"
      @onClose="isOffStation = false"
    />
    <inside
      :isInside="isInside"
      :file="{
        id: offStationData.fileId,
        fileName: offStationData.originalFileName,
        savePath: offStationData.savePath,
        fileSuffix: offStationData.fileSuffix
      }"
      @onClose="isInside = false"
    />
    <el-dialog
      :visible="isExportFileDialog"
      width="358px"
      :wrapperClosable="true"
      :title="$t('Files.Choose Download Content')"
      :before-close="() => isExportFileDialog = !isExportFileDialog"
      v-move-outside
    >
      <div class="exportFileBox">
        <div class="item" :class="exportActive == 1 ? 'active' : ''" @click="exportActive = 1">
          <i class="iconfont icon-a-wenjianliebiao"></i>
          <div class="text">{{ $t('home.Files') }}+{{ $t('home.List') }}</div>
        </div>
        <div class="item" :class="exportActive == 2 ? 'active' : ''" @click="exportActive = 2">
          <i class="iconfont icon-jinwenjian"></i>
          <div class="text">{{ $t('order.Files Only') }}</div>
        </div>
        <div class="item" :class="exportActive == 3 ? 'active' : ''" @click="exportActive = 3">
          <i class="iconfont icon-jinliebiao"></i>
          <div class="text">{{ $t('Files.List only') }}</div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="isExportFileDialog = false;"
          size="small">
          {{ $t('Cancel') }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-download"
          :disabled="!exportActive"
          @click="fileExportSubmit">
          {{ $t('Confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

import offStation from "@/components/offStation/offStation.vue";
import inside from "@/components/fileInside/inside.vue";

import { getTemporaryFile } from "@/api/files";

import { 
  getClassifyList, 
  downloadFile, 
  fileConfigDelete, 
  downloadZip,
  getListCompanyFileConfig
} from "@/api/files";

export default {
  name: "FileDetails",
  components: {
    offStation,
    inside,
  },
  data() {
    return {
      exportActive: 1,
      isLoading: false,
      isExportFileDialog: false,
      isOffStation: false,
      isInside: false,
      tableSelectedShow: null,
      companyList: [],
      offStationData: {},
      tableData: [],
      fileTypeFilter: [],
      filteredValue: [],
      importanceOptions: [
        {
          value: "fileName",
          label: this.$t("Files.Filename"),
        },
        {
          value: "pi",
          label: this.$isOrderPITitle(),
        },
        {
          value: "ci",
          label: this.$t("Files.CI"),
        },
        {
          value: "uploadPerson",
          label: this.$t("Files.Uploader"),
        },
      ],
      dateRange: [],
      form: { 
        queryType: "",
        queryValue: "", 
        fileType: "",
        sortField: "",
        sortType: "",
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      },
      multipleSelection: [],
      isExportFileDialog: false,
      inputStatus: true,
      place_content: this.$t("Files.inputcontent"),
      embedShow: false,
      embedObj: null,
      embedUrl: "",
      embedRow: {},
      companyCustomerFileConfig: []
    };
  },
  computed: {
    ...mapGetters(["fileType", "updateCountOrder"])
  },
  watch: {
    filteredValue(newData, oldData) {
      this.form.fileType = newData[0];
      this.getList(true);
    },
    //数据动态刷新
    updateCountOrder() {
      this.getList(true);
    },
  },
  mounted() {
    this.getListCompanyFileConfig();
    this.getList();
  },
  methods: {
    getListCompanyFileConfig() {
      getListCompanyFileConfig().then((data) => {
        this.companyCustomerFileConfig = data || [];
      })
    },
    openExportFileDialog() {
      if(!this.multipleSelection.length) {
        this.$message.warning(this.$t('Please select the data to operate on'));
        return;
      }
      this.isExportFileDialog = true;
    },
    getRowKeys(row) {
      return row.fileId;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    fileTypeCommand(row) {
      this.form.fileType = row;
      this.getList(true);
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    sortChange(data) {
      const { order, prop } = data;
      if(order) {
        this.form.sortField = prop;
        this.form.sortType = order == "ascending" ? "asc" : "desc";
      } else {
        this.form.sortType = "";
        this.form.sortField = "";
      }
      this.getList(true);
    },
    getList(isPage) {
      if(isPage) {
        this.form.currentPage = 1;
      }
      this.isLoading = true;
      getClassifyList({
        ...this.form,
        startTime: this.dateRange.length ? dayjs(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.dateRange.length ? dayjs(this.dateRange[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"): ''
      }).then((response) => {
        if (!response) return;
        this.form.totalElement = response.total;
        this.tableData = response.list.map(item => ({
          ...item,
          fileSize: Number(item.fileSize)
        }));
      }).finally(() => (this.isLoading = false));
    },
    linkTo(path) {
      this.$router.push({
        path,
      });
    },
    selectTrigger(val) {
      if (val) {
        this.inputStatus = false;
        if (val == "fileName") {
          this.place_content = this.$t("Files.Type File name");
        } else if (val == "pi") {
          this.place_content = this.$isOrderPITitle();
        } else if (val == "ci") {
          this.place_content = this.$t("Files.Type CI number");
        } else if (val == "uploadPerson") {
          this.place_content = this.$t("Files.Type Uploader name");
        }
      } else{
        this.inputStatus = true;
        this.place_content = this.$t("Files.inputcontent");
      }
    },
    clearFilter() {
      this.form = { 
        queryType: "",
        queryValue: "", 
        fileType: "",
        sortField: "",
        sortType: "",
        currentPage: 1,
        pageSize: 10,
        totalElement: 0
      }
      this.getList(true);
    },
    async fileExportSubmit() {
      if(this.exportActive == 1) {
        await this.handleDownLoadZip();
        this.handleDownload();
      }
      if(this.exportActive == 2) {
        this.handleDownLoadZip();
      }
      if(this.exportActive == 3) {
        this.handleDownload();
      }
    },
    handleDownLoadZip() {
      return new Promise((resolve, reject) => {
        downloadZip(this.multipleSelection.map(item => item.fileId)).then((data) => {
          console.log(data);
          //1.我们需要通过blob对象来处理，需要模拟一个<a>标签来提供下载链接
          const elink = document.createElement('a');
          elink.style.display = 'none';
          //2.blob是二进制大对象，接受后台返回的数据流，导出数据
          const blob = new Blob([data], { type: 'application/zip' });
          //3.创建一个url 对象，并将blob实例作为参数传入，这样href的值就是以blob开头的一个文件流下载链接,类似于href="blob:http://127.0.0.1xxxxx"
          const blobUrl = URL.createObjectURL(blob);
          elink.href = blobUrl;
          elink.download = 'File Zip';//下载后的文件名
          //4.将a标签加入body,并手动点击触发下载
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    handleDownload() {
      var tHeader = [
        this.$t('Files.Filename'),
        this.$t('home.Event')+' '+this.$t('Files.Name'),
        "PI",
        "CI",
        this.$t('Files.Type'),
        this.$t('Files.Size(KB)'),
        this.$t('Files.Format'),
        this.$t('Files.Creator'),
        this.$t('Files.CreateTime')
      ];
      var filterVal = [
        "originalFileName",
        "subject",
        "pi",
        "ci",
        "fileType",
        "fileSize",
        "fileSuffix",
        "updatePerson",
        "createTime"
      ];
      var filename = this.$t('eventExport.eventFile');
      var data = this.formatJson(filterVal, this.multipleSelection.map(item => ({
        ...item,
        updatePerson: item.openUserDTO.nickName,
        fileType: this.$isFindData(this.companyCustomerFileConfig, item.fileType, 'configCode', 'configName')
      })));
      this.$export_json_to_excel({
        header: tHeader,
        data,
        filename,
      });
    },
    formatJson(filterVal, tableData) {
      return tableData.map((v) => {
        return filterVal.map((j) => {
          return v[j];
        });
      });
    },
    //预览，下载，分享，删除
    operation(item, type) {
      switch (type) {
        case "预览":
          this.offStationData = item;
          this.embedUrl = item.savePath;
          this.embedShow = true;
          break;
        case "下载":
          // downloadFile(item.fileId)
          //   .then((response) => {
          //     if (!response) return;
          //     let downloadUrl = URL.createObjectURL(response);
          //     let a = document.createElement("a");
          //     a.href = downloadUrl;
          //     a.download = item.originalFileName; //下载后文件名
          //     a.click();
          //     a = null;
          //     downloadUrl && URL.revokeObjectURL(downloadUrl);
          //   })
          //   .catch((error) => {});
          getTemporaryFile(item.savePath).then((data) => {
            // 使用a标签下载，禁止跳转
            const a = document.createElement('a');
            a.href = data;
            a.download = data;
            a.click();
          })
          break;
        case "站内分享":
          this.isInside = true;
          this.offStationData = item;
          break;
        case "站外分享":
          this.offStationData = item;
          this.isOffStation = true;
          break;
        case "删除":
          this.$confirm("Are you sure you want to delete the file?", "Tips", {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
          }).then(() => {
            fileConfigDelete({ fileId: item.fileId }).then((res) => {
              const { data, msg, success } = res.data;
              if (success) {
                this.$message.success("Deletion succeeded");
                this.getList(true);
              } else {
                this.$message.error(data || msg);
              }
            });
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.data_ci {
  color: rgb(91, 145, 189);
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.data_pi {
  color: #606266 !important;
  text-decoration: none !important;
}
.exportFileBox {
  display: flex;
  gap: 9px;
  .item {
    flex: 1;
    height: 103px;
    background: #F7F9FC;
    border: 1px solid #C7DDD8;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    justify-content: center;
    transition: all .3s;
    cursor: pointer;
    &.active {
      border-color: #076F49;
      i {
        color: #076F49;
      }
      .text {
        font-weight: 600;
        color: #122545;
      }
    }
    i {
      font-size: 32px;
      color: #A3B0C6;
    }
    .text {
      font-size: 14px;
      color: #637381;
      line-height: 20px;
    }
    &:hover {
      .active;
    }
  }
}
</style>
